var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "js-transition-icon",
      attrs: {
        width: _vm.size.width + "px",
        height: _vm.size.height + "px",
        viewBox: "0 0 " + _vm.size.width + " " + _vm.size.height
      },
      on: {
        click: _vm.onClick,
        mouseenter: _vm.onHover,
        mouseleave: _vm.onHover
      }
    },
    [
      _c(
        "g",
        { staticClass: "js-transition-icon__state" },
        [_vm._t("initial")],
        2
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          staticClass: "js-transition-icon__state",
          staticStyle: { display: "none" }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }